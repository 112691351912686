import React from "react"
import { Container, Col, Row, Image, Figure } from "react-bootstrap"
import { graphql } from "gatsby"
//import { Link } from "gatsby"
import Layout from "../../components/layoutHome"
import SEO from "../../components/seo"
import { makeStyles} from '@material-ui/core/styles';
import {
    Typography,
    Grid,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
Title:{
        width:"100%",
        display:"flex",
        justifyContent:"Center",
        //margin:"30px",
        height:"30px",
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        background: "rgba(71,  0,  0, 0.8)",
        color:"white",
        fontSize:"28px",
        alignItems:"center"
      },
      SubTitle:{
        width:"100%",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        marginBottom:"10px",
        textAlign:"justify",
        background: "rgba(71,  0,  0, 0.8)",
        color:"white",
        
      },
      imgContainer:{
          padding:"5px",
          width:"100%",
          display:"flex",
          justifyContent:"center",
          alignItems:"center",

      },
      imgText:{
          textAlign:"justify",

      },
      img:{
          border:"black",
          borderStyle:"groove"
      },
      GridContainer:{
          margin:"10px"
      },
      EventContainer:{
        margin:"10px"
      },
      TextEvent:{
        width:"100%",
        padding:"10px",
        textAlign:"justify"
      },
      Seccion:{
          marginTop:"10px",
          marginBottom:"10px",
          paddingLeft:"10px",
          paddingRight:"10px"
      },
      espacio:{
          padding:"5px"
      },
      Principal:{
        maxHeight:"550px",
        maxWidth:"100%",
        padding:"10px",
      }

  }));

const Convenios  = ({ data }) => {
  const Model = data.allCeformaPagesShow12.edges[0].node.data.page.content.calendar
  const classes = useStyles(); 
  console.log(Model)
  return (
    <Layout>
      <SEO title="Calendario" />
      <Container fuid>
        <Typography className={classes.Title}  >
            Calendario
        </Typography>

        <Grid Container>
           
        {
        Model.length > 0 ? (
          <Grid container 
          style={{
            maxWidth:"100%",
            overflow:"auto"
          }}
          
          >
        
        <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                    <TableCell>Fecha</TableCell>
                                      <TableCell>SEDE</TableCell>
                                      <TableCell>Titulo</TableCell>
                                      <TableCell>Temas</TableCell>
                                      <TableCell>Participantes</TableCell>
                                    </TableRow>
                                  </TableHead>
                                <TableBody>
                              {Model.map((Article , index) => {
                              return (
                                      <React.Fragment>
                                          <TableRow key={Article.uuid}>
                                          <TableCell component="th" scope="row">{Article.date}</TableCell>
                                          <TableCell component="th" scope="row">{Article.place}</TableCell>
                                          <TableCell component="th" scope="row">{Article.title}</TableCell>
                                          <TableCell component="th" scope="row">{Article.topics}</TableCell>
                                          <TableCell component="th" scope="row">{Article.participants}</TableCell>
                                          </TableRow>
                                      </React.Fragment>      
                                      );
                                    })}
                              </TableBody>
                              </Table>
             
        </Grid>
          ) : (
            <Paper className={classes.Table}>
          
          </Paper>
     )}
            
        </Grid>
      </Container>
    </Layout>
  )
}

export default  Convenios 

export const query = graphql`
  query calendario {
    allCeformaPagesShow12 {
        edges {
          node {
            data {
              page {
                content {
                  calendar {
                    date
                    participants
                    place
                    title
                    topics
                  }
                }
              }
            }
          }
        }
      }
  }
`
